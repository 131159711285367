import React, { useState, useCallback, useEffect, useMemo } from "react";
import { useLocation, useNavigate, Outlet } from "react-router-dom";
import Header from "@/components/Header";
import "../common/css/common.scss";
import Connect from "@/components/Connect";
import NavigationModal from "@/components/NavigationModal";
import { api_haiboPhone, mtClick } from "@/api/api";
import { getQueryString } from "@/utils/utility";
import { usePhoneNumberState } from "@/utils/PhoneNumberContext";

const userLayout = () => {
  const { pathname } = useLocation();
  const [showNavigation, setShowNavigation] = useState(false);
  const { phoneNumber, setPhoneNumber } = usePhoneNumberState();

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowNavigation(true);
    }, 10000);

    getPhone();

    checkUrlParameters();

    return () => clearTimeout(timer);
  }, []);

  // 检查url是否带有参数
  const checkUrlParameters = () => {
    if (getQueryString("from") == 1) {
      setDjMs("isFromJDDJ", "到家来源");
    }
  };

  // 来源埋点
  const setDjMs = async (clickId, clickName) => {
    await mtClick("", "", clickId, clickName, "", "", {}, {});
  };

  const getPhone = () => {
    api_haiboPhone({})
      .then((res) => {
        if (res && res.result) {
          setPhoneNumber(res.result.contractPhone);
        }
      })
      .catch((e) => {
        console.log(e);
        setPhoneNumber("");
      });
  };
  return (
    <div className="min_width">
      {showNavigation && pathname == "/home" ? (
        <NavigationModal
          isShow={showNavigation}
          onClose={() => {
            setShowNavigation(false);
          }}
        />
      ) : null}
      <Connect />
      <Header />
      {/* 使用 <Outlet /> 渲染子路由 */}
      <Outlet />
    </div>
  );
};
export default userLayout;
