import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Carousel } from "antd";
import BrandList from "@/components/BrandList";
import Apply from "@/components/Apply";
import Footer from "@/components/Footer";
import ApplyModal from "@/components/ApplyModal";
import { mtClick } from "@/api/api";
import { Popover } from "@hibo/HiboBiz";
// import homePageLong from '@/images/homePage/homepage_long.png';
// import supermarketImage from '@/images/homePage/supermarket_scene.png';
// import frontWarehouseImage from '@/images/homePage/front_scene.png';
const homePageLong =
  "https://static.hiboos.com/static/yzt-home/homePage/homepage_long.png";
const supermarketImage =
  "https://static.hiboos.com/static/yzt-home/homePage/supermarket_scene.png";
const frontWarehouseImage =
  "https://static.hiboos.com/static/yzt-home/homePage/front_scene_new.png";
const takeOutImage =
  "https://static.hiboos.com/static/yzt-home/homePage/takeout_scene_new.png";
const tabList = [
  {
    label: "超市场景",
    value: 0,
  },
  {
    label: "前置仓场景",
    value: 1,
  },
  {
    label: "外卖场景",
    value: 2,
  },
];
const carouselList = [
  "no-warehouse",
  "supermarkets",
  "front-warehouse",
  "take-out-v",
];
const serviceStandardList = [
  {
    icon: "service_1_icon",
    text: "项目实施交付",
    detail:
      "专业的交付团队，结合商户业务场景，制定个性化交付方案，为每一个商户提供匠心服务。",
  },
  {
    icon: "service_2_icon",
    text: "7*24小时服务",
    detail:
      "高效的客户服务团队，提供日常的业务咨询服务，做到所有的咨询得到有效处理。",
  },
  {
    icon: "service_3_icon",
    text: "定期业务复盘",
    detail:
      "与商户深度建联，定期现场或远程为商户复盘，分析过程并输出业务规划方向。",
  },
  {
    icon: "service_4_icon",
    text: "经营诊断",
    detail: "针对商户日常经营现状，给出合理的经营指导建议，帮助商户有序经营。",
  },
  {
    icon: "service_5_icon",
    text: "大促保障",
    detail: "资源弹性扩容，全链路压测，售后值班看守，小组专项保障。",
  },
];
const serviceTechLeftList = [
  {
    bigText: "99.999%",
    smallText: "系统可用",
  },
  {
    bigText: "99.999%",
    smallText: "一致率",
  },
  {
    bigText: "150毫秒",
    smallText: "服务平均响应",
  },
];
const serviceTechRightList = [
  {
    icon: "service_tech_1",
    text: "服务稳定",
    detail:
      "系统数据在北京/宿迁/上海/广州多数据中心备份，支持主从热备架构，故障自动转移，完善的数据自动备份机制，支持多数据中心自动备份，保障业务数据安全。",
  },
  {
    icon: "service_tech_2",
    text: "弹性伸缩",
    detail:
      "依托容器化部署引擎，自动调整资源规模，系统负载触发阈值自动增加实例，同时自动关联负载均衡，快速化解瞬时的流量洪峰，保证服务的可用性。",
  },
  {
    icon: "service_tech_3",
    text: "异地多活",
    detail:
      "跨区多机房多可用区部署，基于智能负载均衡算法，实现流量智能调度，单点出现故障，可实现秒级备用节点拉起，保证服务的持续稳定性。",
  },
];

const Home = () => {
  const navigate = useNavigate();
  const [tabsActive, setTabsActive] = useState(0);
  const [sceneImage, setSceneImage] = useState(supermarketImage);
  const [showModal, setShowModal] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0); // 跳转后滚动到顶部
    // setDjMs("ofHomePage", "首页");
  }, []);

  // 埋点
  const setDjMs = async (clickId, clickName) => {
    await mtClick("", "", clickId, clickName, "", "", {}, {});
  };

  const modalShow = () => {
    if (!showModal) {
      setShowModal(true);
    }
  };
  const onCancel = () => {
    setShowModal(false);
  };
  const handleChange = (value) => {
    let tabImage =
      value == 0
        ? supermarketImage
        : value == 1
        ? frontWarehouseImage
        : takeOutImage;
    setTabsActive(value);
    setSceneImage(tabImage);
  };
  const routerSkip = (value) => {
    console.log(value);
    // const key = value.split('_new')[0]
    navigate(`/${value}`);
  };
  const handleBeforeChange = (from, to) => {
    setCurrentIndex(to);
  };

  const handleMouseEnter = () => {
    setDjMs("func_BuyNow", "立即购买");
  };

  return (
    <div className="home_wrapper">
      <ApplyModal isShow={showModal} onCancel={onCancel} />
      <div className="carousel_wrapper_home">
        <div className="btn_wrapper">
          <p className="btn btn_default" onClick={modalShow}>
            免费咨询
          </p>
          {/* 只有外卖版展示购买按钮 */}
          {currentIndex === 3 && (
            <Popover
              trigger="hover"
              placement="top"
              onMouseEnter={handleMouseEnter}
              content={
                <img
                  src="https://static.hiboos.com/static/yzt-home/homePage/buy_qrcode.png"
                  width={188}
                  height={231}
                  alt=""
                />
              }
            >
              <p className="btn btn_primary" style={{ marginLeft: "10px" }}>
                立即购买
                <img
                  src="https://static.hiboos.com/static/yzt-home/homePage/discount.png"
                  width={60}
                  height={18}
                  alt=""
                />
              </p>
            </Popover>
          )}
        </div>
        <Carousel autoplay beforeChange={handleBeforeChange}>
          {carouselList.map((key, index) => {
            return (
              <img
                key={index}
                style={{
                  cursor: "pointer",
                  width: "100%",
                  position: "relative",
                }}
                src={`https://static.hiboos.com/static/yzt-home/homePage/${key}.webp`}
                alt=""
                onClick={() => {
                  routerSkip(key);
                }}
              />
            );
          })}
        </Carousel>
      </div>

      <div className="work_scene">
        <div className="scene_wrapper">
          <div className="tabs">
            {tabList.map((item) => {
              return (
                <span
                  key={item.value}
                  className={
                    tabsActive == item.value
                      ? "tabs_item tabs_item_active"
                      : "tabs_item"
                  }
                  onClick={() => {
                    handleChange(item.value);
                  }}
                >
                  {item.label}
                </span>
              );
            })}
          </div>
          <div className="scene_icon">
            <div className="wrapper">
              <img height={500} key={sceneImage} src={sceneImage} alt="" />
            </div>
          </div>
        </div>

        <div className="service_standard">
          <div className="wrapper">
            <p className="title_big">专业客户团队 提供一站式服务标准</p>
            <p className="title_small">
              服务14000+合作伙伴，帮助数千企业实现了管理的信息化
            </p>
            <div className="card_wrapper">
              {serviceStandardList.map((item, index) => {
                return (
                  <div className="card_item" key={index}>
                    <div>
                      {/* <img height={50} src={require(`@/images/homePage/${item.icon}.png`)} alt="" /> */}
                      <img
                        height={50}
                        src={`https://static.hiboos.com/static/yzt-home/homePage/${item.icon}.png`}
                        alt=""
                      />
                      <p className="text">{item.text}</p>
                    </div>
                    <div className="popup">
                      <p className="text">{item.text}</p>
                      <p className="text_content">{item.detail}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="service_tech">
          <div className="wrapper">
            <p className="title_big">服务成就典范 技术成就领先</p>
            <p className="title_small">360°安全保障，规避企业经营安全风险</p>
            <div className="main_content">
              <div className="left_content">
                {serviceTechLeftList.map((item, index) => {
                  return (
                    <div className="item" key={index}>
                      <div className="item_wrapper">
                        <p className="big_text">{item.bigText}</p>
                        <p className="small_text">{item.smallText}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="right_content">
                {serviceTechRightList.map((item, index) => {
                  return (
                    <div className="item" key={index}>
                      <div className="content">
                        {/* <img height={72} src={require(`@/images/homePage/${item.icon}.png`)} alt="" /> */}
                        <img
                          height={72}
                          src={`https://static.hiboos.com/static/yzt-home/homePage/${item.icon}.png`}
                          alt=""
                        />
                        <p className="text">{item.text}</p>
                      </div>
                      <div className="description">
                        <p>{item.detail}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="security_certificate">
          <p className="title_big">通过国内及国际权威机构的信息安全认证</p>
          <div className="main_content">
            <BrandList imgsrc={homePageLong} styles={{ width: "1976px" }} />
          </div>
        </div>
        <Apply />
        <Footer />
      </div>
    </div>
  );
};
export default Home;
