// 同一个js只会加载一次，区别方式为src
// 也就是说同一个src，不同的attributes，只会加载第一次
export const loadjs = (src, attributes) => {
    if (loadjs[src]) {
        if (loadjs[src].done) {
            //return $.Deferred(function (d) { d.resolve() });
            return new Promise((resolve) => {
                resolve('');
            });
        }
        else if (loadjs[src].loading) {
            return loadjs[src].Deferred;
        }
    }
    loadjs[src] = {
        loading: true,
        Deferred: new Promise((resolve, reject) => {
            var s = document.createElement('script');
            s.src = src;
            if (attributes && typeof attributes === 'object') {
                for (var name in attributes) {
                    s.setAttribute(name, attributes[name]);
                }
            }
            s.onload = function () {
                loadjs[src].done = true;
                loadjs[src].loading = false;
                loadjs[src].Deferred = null;
                resolve(s);
            };
            s.onerror = function () {
                loadjs[src] = null;
                reject(s);
            };
            var p = document.getElementsByTagName('head')[0];
            p.appendChild(s);
        })
    };
    return loadjs[src].Deferred;
};
