// 埋点库主入口
import { utils } from './utils';
import { loadjs } from './loadjs';
export const DJMtaUtils = {
    proBasicParams: {},
    deviceBasicParams: {},
    epMap: [],
    epNum: 10,
    count: 0,
    dynamicFunc: function () { },
    customAjax: function () { },
    isCustomAjax: false,
    needEncrypt: false,
    sdkType: '0',
    initOver: false,
    getTime() {
        let now = new Date();
        let create_time = `${now.getFullYear()}-${now.getMonth() +
            1}-${now.getDate()} ${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}.${now.getMilliseconds()}`;
        return {
            //补充时间戳
            clienttime: new Date().getTime(),
            create_time: create_time
        };
    },
    /**
     *
     * @param proBasicParams
     * @param deviceBasicParams
     * @param dynamicFunc
     */
    init(proBasicParams, deviceBasicParams, dynamicFunc, customAjax) {
        // 校验项目基本信息传递是否完整
        utils.verifyProParams(proBasicParams);
        //校验设备基本信息传递是否完整
        utils.verifyDevParams(deviceBasicParams);
        //拿到初始的项目数据，将其保存到全局变量
        this.proBasicParams = proBasicParams;
        this.deviceBasicParams = deviceBasicParams;
        this.needEncrypt = proBasicParams.needEncrypt || false;
        this.sdkType = proBasicParams.sdkType || '0';
        if (proBasicParams.epAssignNum) {
            this.epNum = proBasicParams.epAssignNum;
        }
        this.dynamicFunc = dynamicFunc;
        if (customAjax && typeof customAjax == 'function') {
            this.isCustomAjax = true;
            this.customAjax = customAjax;
        }
        //使用京东子午线sdk
        !proBasicParams.noInit && this.initJDSdk(proBasicParams.siteId);
    },
    /**
     * 京东子午线sdk初始化
     */
    initJDSdk(siteId) {
        try {
            //加载子午线sdk
            loadjs('https://wl.jd.com/unify.min.js').then(() => {
                this.initOver = true;
            });
            //初始化参数
            window.jap = {
                siteId: siteId
            };
        }
        catch (e) {
        }
    },
    // 上报PV埋点
    async sendPvData(firstLevelParams, ext_par, send_type) {
        //拿到一级参数，与全局参数进行拼接上报
        let data = Object.assign(firstLevelParams, this.proBasicParams, this.deviceBasicParams, { ext_par: ext_par });
        let params;
        if (!!this.dynamicFunc) {
            params = await this.dynamicFunc();
        }
        if (this.sdkType == '0') {
            this.sendPvDataByDJ(data, params, send_type);
        }
        if (this.sdkType == '1') {
            this.sendPVDataByJD(data, params, send_type);
        }
        if (this.sdkType == '2') {
            this.sendPvDataByDJ(data, params, send_type);
            this.sendPVDataByJD(data, params, send_type);
        }
    },
    /**
     * 秒送上报PV埋点
     */
    sendPvDataByDJ(data, params, send_type) {
        const method = !!this.isCustomAjax ? this.customAjax : utils.requestApi.bind(utils);
        method([Object.assign(data, params, this.getTime())], send_type || 'h5', this.needEncrypt);
    },
    /**
     * 子午线上报PV埋点
     */
    sendPVDataByJD(data, params, send_type) {
        var _a;
        try {
            if (this.initOver) {
                var pv = new MPing.inputs.PV(); // 构造pv
                var mping = new MPing(); // 构造上报实例
                //给pv添加参数
                pv.pin = params.jdPin;
                pv.chf = data.channel_name;
                pv.ctm = (_a = this.getTime()) === null || _a === void 0 ? void 0 : _a.clienttime;
                pv.ctp = data.url;
                pv.lastPage = data.ref_page_name;
                pv.page_id = data.page_name;
                pv.rpr = data.ref_par;
                pv.par = JSON.stringify(data.url_page_par);
                pv.page_param = JSON.stringify(data.url_page_par);
                pv.log_data_type = send_type;
                pv.std_param = JSON.stringify(params);
                pv.ext = JSON.stringify(Object.assign({}, data.ext_par, { user_id: params.user_id, device_id: params.device_id }));
                mping.send(pv);
            }
            else {
                setTimeout(() => {
                    this.sendPVDataByJD(data, params, send_type);
                }, 0);
            }
        }
        catch (e) {
        }
    },
    // 上报点击埋点
    async sendClickData(firstLevelParams, ext_par, send_type) {
        if (!!firstLevelParams.click_id) {
            let data = Object.assign(firstLevelParams, this.proBasicParams, this.deviceBasicParams, { ext_par: ext_par, click_name: firstLevelParams.click_id });
            let params;
            if (!!this.dynamicFunc) {
                params = await this.dynamicFunc();
            }
            if (this.sdkType == '1') {
                this.sendClickDataByJD(data, params, send_type);
            }
            if (this.sdkType == '0') {
                this.sendClickDataByDJ(data, params, send_type);
            }
            if (this.sdkType == '2') {
                this.sendClickDataByJD(data, params, send_type);
                this.sendClickDataByDJ(data, params, send_type);
            }
        }
    },
    /**
     * 秒送点击事件
     * @param data
     * @param params
     * @param send_type
     */
    sendClickDataByDJ(data, params, send_type) {
        const method = !!this.isCustomAjax ? this.customAjax : utils.requestApi.bind(utils);
        method([Object.assign(data, params, this.getTime())], send_type || 'h5', this.needEncrypt);
    },
    /**
     * 京东点击事件
     * @param data
     * @param params
     * @param send_type
     */
    sendClickDataByJD(data, params, send_type) {
        var _a;
        try {
            if (this.initOver) {
                var eventId = data.click_id; // 必选参数，事件id
                var click = new MPing.inputs.Click(eventId); // 构造点击上报对象
                click.event_param = '';
                click.json_param = JSON.stringify(data.click_par); // 设置click事件参数
                click.event_level = data.event_level; // 设置事件等级
                click.pin = params.jdPin;
                click.chf = data.channel_name;
                click.ctm = (_a = this.getTime()) === null || _a === void 0 ? void 0 : _a.clienttime;
                click.ctp = data.url;
                click.lastPage = data.ref_page_name;
                click.page_id = data.page_name;
                click.rpr = data.ref_par;
                click.par = JSON.stringify(data.url_page_par);
                click.log_data_type = send_type;
                click.std_param = JSON.stringify(params);
                click.ext = JSON.stringify(Object.assign({}, data.ext_par, { user_id: params.user_id, device_id: params.device_id }));
                click.updateEventSeries(); // 更新事件串
                (new MPing()).send(click); // 构造上报实例，上报点击事件
            }
            else {
                setTimeout(() => {
                    this.sendClickDataByJD(data, params, send_type);
                }, 0);
            }
        }
        catch (e) { }
    },
    //上报实时曝光
    async sendSingleEpData(firstLevelParams, ext_par) {
        if (!!firstLevelParams.trace_id && !!firstLevelParams.ep) {
            let params;
            if (!!this.dynamicFunc) {
                params = await this.dynamicFunc();
            }
            let data = Object.assign(firstLevelParams, this.proBasicParams, this.deviceBasicParams, { ext_par: ext_par }, params, this.getTime());
            data.ep = [{ spmId: firstLevelParams.ep, cnt: 1 }];
            if (this.sdkType == '1') {
                this.sendEpByJD(data, params);
            }
            if (this.sdkType == '0') {
                this.sendSingleEpDataDJ(data);
            }
            if (this.sdkType == '2') {
                this.sendSingleEpDataDJ(data);
                this.sendEpByJD(data, params);
            }
        }
    },
    // 秒送上报实时曝光
    sendSingleEpDataDJ(data) {
        const method = !!this.isCustomAjax ? this.customAjax : utils.requestApi.bind(utils);
        method([data], 'show', this.needEncrypt);
    },
    // 秒送上报聚合曝光
    sendEpDataDJ(data) {
        const method = !!this.isCustomAjax ? this.customAjax : utils.requestApi.bind(utils);
        method(data, 'show', this.needEncrypt);
    },
    //子午线曝光函数
    sendEpByJD(data, params) {
        var _a;
        try {
            if (this.initOver) {
                var exposureId = "Home_IconExpo"; // 必选参数，事件id
                var exposure = new MPing.inputs.Exposure(exposureId); // 构造点击上报对象
                exposure.pin = params.jdPin;
                exposure.chf = data.channel_name;
                exposure.ctm = (_a = this.getTime()) === null || _a === void 0 ? void 0 : _a.clienttime;
                exposure.ctp = data.url;
                exposure.page_id = data.cur_page;
                exposure.rpr = data.ref_par;
                exposure.page_param = JSON.stringify(data.url_page_par);
                exposure.log_data_type = 'show';
                exposure.std_param = JSON.stringify(params);
                exposure.json_param = JSON.stringify(data.ep);
                exposure.ext = JSON.stringify(Object.assign({}, data.ext_par, { user_id: params.user_id, device_id: params.device_id, trace_id: data.trace_id }));
                (new MPing()).send(exposure); // 构造上报实例，上报点击事件
            }
            else {
                setTimeout(() => {
                    this.sendEpByJD(data, params);
                }, 0);
            }
        }
        catch (e) { }
    },
    sendEp(data, params) {
        if (this.sdkType == '1') {
            data.forEach((v) => {
                this.sendEpByJD(v, params);
            });
        }
        if (this.sdkType == '0') {
            this.sendEpDataDJ(data);
        }
        if (this.sdkType == '2') {
            this.sendEpDataDJ(data);
            data.forEach((v) => {
                this.sendEpByJD(v, params);
            });
        }
    },
    //聚合曝光
    async sendMultiEpData(firstLevelParams, ext_par) {
        if (!!firstLevelParams.trace_id && !!firstLevelParams.ep) {
            // 这里得有个逻辑在。聚合的逻辑
            const { trace_id, ep, cur_page } = firstLevelParams;
            const { pageId } = ext_par;
            //聚合一下其他的参数
            let params;
            if (!!this.dynamicFunc) {
                params = await this.dynamicFunc();
            }
            let extraData = Object.assign(firstLevelParams, this.proBasicParams, this.deviceBasicParams, { ext_par: ext_par }, params, this.getTime());
            let traceObj = this.epMap.find((value) => value.traceId == trace_id);
            if (traceObj) {
                const epList = traceObj === null || traceObj === void 0 ? void 0 : traceObj.ep;
                // 拿到traceObj的索引，用于从epMap中删除使用
                const startIndex = this.epMap.findIndex((item) => item.traceId == trace_id);
                // 如果有Useraction
                const curUserAction = epList.find((value) => value.spmId == ep);
                if (curUserAction) {
                    this.epMap[startIndex].ep.map((v) => {
                        if (v.spmId == ep) {
                            v.cnt++;
                        }
                    });
                }
                else {
                    this.epMap[startIndex].ep.push({ spmId: ep, cnt: 1 });
                    let uploadData = JSON.parse(JSON.stringify(this.epMap));
                    // epList.push({ spmId: userAction, cnt: 1 });
                    // 同一个traceid下的ep超过阈值，上报
                    //组合traceid进行上报；总共10条就上报
                    this.count++;
                    if (this.count >= this.epNum) {
                        //先删除epMap中对应的uploadData中的内容,,,,,这里怎么能把这些去除呢
                        uploadData.forEach((item) => {
                            var _a, _b;
                            //如果这个时候this.epMap发生变化了呢 怎么办？应该只删除对应的traceId下的10条
                            let delIndex = this.epMap.findIndex((item1) => item1.traceId == item.traceId);
                            let getEp = (_b = (_a = this === null || this === void 0 ? void 0 : this.epMap) === null || _a === void 0 ? void 0 : _a[delIndex]) === null || _b === void 0 ? void 0 : _b.ep;
                            // this.epMap.splice(delIndex, 1);
                            if ((getEp === null || getEp === void 0 ? void 0 : getEp.length) == this.epNum) {
                                this.epMap.splice(delIndex, 1);
                            }
                            else {
                                getEp === null || getEp === void 0 ? void 0 : getEp.splice(0, this.epNum - 1);
                            }
                        });
                        this.count = 0;
                        let joinData = [];
                        uploadData.forEach((epItem) => {
                            if (!epItem.traceId || (!epItem.ep || (!!epItem.ep && epItem.ep.length == 0)))
                                return true;
                            let newItem = JSON.parse(JSON.stringify(Object.assign(extraData, epItem)));
                            joinData.push(newItem);
                            return true;
                            // console.log('满足10：', epItem, extraData);
                        });
                        this.sendEp(joinData, params);
                        // const method = !!this.isCustomAjax ? this.customAjax : utils.requestApi.bind(utils);
                        // method(joinData, 'show',this.needEncrypt);
                        // //上报
                        // this.hasTime = false;
                        // DJMta.sendEpData({ epData: uploadData, cur_page: pageName, clientTime: this.clientTime });
                        uploadData = {};
                    }
                }
            }
            else {
                this.count++;
                //插入一条新的traceid
                this.epMap.push({
                    traceId: trace_id,
                    ep: [{ spmId: ep, cnt: 1 }],
                    cur_page: cur_page,
                    pageId: pageId
                });
                let uploadData = JSON.parse(JSON.stringify(this.epMap));
                if (this.count >= this.epNum) {
                    //先删除epMap中对应的uploadData中的内容,,,,,这里怎么能把这些去除呢
                    uploadData.forEach((item) => {
                        var _a, _b;
                        let delIndex = this.epMap.findIndex((item1) => item1.traceId == item.traceId);
                        // this.epMap.splice(delIndex, 1);
                        let getEp = (_b = (_a = this === null || this === void 0 ? void 0 : this.epMap) === null || _a === void 0 ? void 0 : _a[delIndex]) === null || _b === void 0 ? void 0 : _b.ep;
                        if ((getEp === null || getEp === void 0 ? void 0 : getEp.length) == this.epNum) {
                            this.epMap.splice(delIndex, 1);
                        }
                        else {
                            getEp === null || getEp === void 0 ? void 0 : getEp.splice(0, this.epNum - 1);
                        }
                    });
                    this.count = 0;
                    let joinData = [];
                    uploadData.forEach((epItem) => {
                        if (!epItem.traceId || (!epItem.ep || (!!epItem.ep && epItem.ep.length == 0)))
                            return true;
                        let newItem = JSON.parse(JSON.stringify(Object.assign(extraData, epItem)));
                        joinData.push(newItem);
                        return true;
                        // console.log('满足10：', epItem, extraData);
                    });
                    this.sendEp(joinData, params);
                    // const method = !!this.isCustomAjax ? this.customAjax : utils.requestApi.bind(utils);
                    // method(joinData, 'show',this.needEncrypt);
                    // //上报
                    // DJMta.sendEpData({ epData: uploadData, cur_page: pageName, clientTime: this.clientTime });
                    uploadData = {};
                }
            }
        }
    },
    // 上报曝光池中，所有的曝光埋点
    async sendAllEpData(firstLevelParams, ext_par) {
        if (this.epMap.length > 0) {
            let joinData = [];
            let uploadData = JSON.parse(JSON.stringify(this.epMap));
            let params;
            if (!!this.dynamicFunc) {
                params = await this.dynamicFunc();
            }
            let extraData = Object.assign(firstLevelParams, this.proBasicParams, this.deviceBasicParams, { ext_par: ext_par }, params, this.getTime());
            uploadData.forEach((epItem) => {
                if (!epItem.traceId || (!epItem.ep || (!!epItem.ep && epItem.ep.length == 0)))
                    return true;
                let newItem = JSON.parse(JSON.stringify(Object.assign(extraData, epItem)));
                if (newItem && newItem.pageId) {
                    newItem.ext_par.pageId = newItem.pageId;
                    delete newItem.pageId;
                }
                joinData.push(newItem);
                return true;
            });
            this.sendEp(joinData, params);
            // const method = !!this.isCustomAjax ? this.customAjax : utils.requestApi.bind(utils);
            // method(joinData, 'show',this.needEncrypt);
            this.epMap = [];
            // 清除上一页的count
            this.count = 0;
        }
    },
    // 自定义埋点上报
    sendCustomData(firstLevelParams, ext_par, send_type) {
        const method = !!this.isCustomAjax ? this.customAjax : utils.requestApi.bind(utils);
        method(Object.assign(firstLevelParams, { ext_par: ext_par }), send_type, this.needEncrypt);
    },
    // 动态更新数据
    updateEncryptStatus(needEncrypt) {
        this.needEncrypt = needEncrypt;
    },
    updateburiedSwitchStatus(sdkType) {
        this.sdkType = sdkType;
    }
};