import bigDecimal from 'js-big-decimal';
const _ = require('lodash/object');

/* 获取url上的参数 */
export function getQueryString(name, url = window.location.search) {
  const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`, 'i');
  let expStr = window.location.hash;
  let r2 = null;
  if (expStr.indexOf('?') !== -1 || expStr.indexOf('&') !== -1) {
    expStr = expStr.split('?')[1] || expStr.split('&')[1];
    r2 = expStr && expStr.match(reg);
  }
  const targetStr = url.substr(1);
  const r1 = targetStr.match(reg);
  if (r1 != null) {
    // return unescape(r1[2]);
    return decodeURI(r1[2]);
  } else if (r2 != null) {
    // return unescape(r2[2]);
    return decodeURI(r2[2]);
  }
  return null;
}
// 查询条件清空空格
export function trimQueryInfo(data = {}) {
  let params = _.defaultsDeep({}, data);
  let temp = {};
  for (let k in params) {
    // 只处理字符串
    if (typeof params[k] === 'string') {
      let value = params[k].trim();
      if (value !== '') {
        temp[k] = value;
      }
    } else {
      temp[k] = params[k];
    }
  }
  return temp;
}
// 数组转json
export function ArrToObj(data = [], key, value) {
  let res = {};
  if (data instanceof Array) {
    data.map(item => {
      res[item[key || 'value']] = item[value || 'label'];
    });
  }
  return res;
}
// 给数字加分隔符, 每三位加一个逗号 参数：num需要处理的数字，centToYuan是否需要把分转为元，yuanToCent是否需要把元转为分
export function AddNumberSeparator({ num, centToYuan, yuanToCent, addMoney }) {
  if (num === undefined || num === '') return num;

  let curNum = num;
  if (centToYuan) {
    curNum = Number(num / 100).toFixed(2);
  }
  if (yuanToCent) {
    curNum = Number(num * 100).toFixed(2);
  }

  var res = curNum.toString().replace(/\d+/, function (n) {
    // 先提取整数部分
    return n.replace(/(\d)(?=(\d{3})+$)/g, function ($1) {
      return $1 + ',';
    });
  });
  if (addMoney) {
    res = '¥' + res;
  }
  return res;
}

/**
* 把source按parentId转换成树形图
* 参数：
* source：原数组
* parentId：父级id，默认第一级的父级id是 0
* childKey：转换后子级list的key
* parentKey：父级的惟一标识
* curKey：每条数据的惟一标识
* source示例：
// var list = [
//   {id: 1, name: '菜单一', parentId: 0},
//   {id: 2, name: '菜单二', parentId: 0},
//   {id: 3, name: '菜单三', parentId: 1},
//   {id: 5, name: '菜单五', parentId: 2},
// ];
*/
export function treeConvert(
  source = [],
  parentId = 0,
  childKey = 'children',
  parentKey = 'parentId',
  curKey = 'id',
) {
  // 如果传入的source 不是数组，或者是空数组，则返回空数组
  if (Object.prototype.toString.call(source) !== '[object Array]' || source.length <= 0) {
    return [];
  }

  let res = [];
  // DFS写法
  source.forEach(item => {
    if (item[parentKey] === parentId) {
      let children = treeConvert(source, item[curKey]);
      if (children.length > 0) {
        item.children = children;
      }
      res.push(item);
    }
  });

  // let map = new Map();
  // source.forEach(item => {
  //     map.set(item[curKey], Object.assign({}, item));
  // })

  // for(let item of map.values()){
  //     if(item[parentKey] === parentId || item[parentKey] === undefined){
  //         res.push(item);
  //     }else{
  //         let parent = map.get(item[parentKey]);
  //         parent[childKey] = parent[childKey] || [];
  //         parent[childKey].push(item);
  //     }
  // }
  return res;
}


// 传参清空空格: 递归处理嵌套对象
export function customTrim(data = {}, temp={}) {
  let params = _.defaultsDeep({}, data);

  for (let k in params) {
    // 只处理字符串
    if (typeof params[k] === 'string') {
      let value = params[k].trim();
      if (value !== '') {
        temp[k] = value;
      }
    } else if(Object.prototype.toString.call(params[k]) === '[object Object]'){
      temp[k] = {};
      customTrim(params[k], temp[k]);
    }else {
      temp[k] = params[k];
    }
  }
  return temp;
}

// 数字转汉字
export function NumToChar(num){
  const menu = {0: '零', 1: '一', 2: '二', 3: '三', 4: '四', 5: '五', 6: '六', 7: '七', 8: '八', 9: '九', 10: '十', };
  return menu[num] || '';
}

/**
 * 
 * 大数除法 
 * @param {*} num 除数
 * @param {*} base 被除数
 * @param {*} decimal 转换后的小数位数，默认为2 不保留小数
 * 
 */
export function bigDiv(num, base, decimal = 2) {
  let temp = Number(num), baseTemp = Number(base);
  if (temp && baseTemp) {
    return bigDecimal.divide(temp, baseTemp, decimal);
  } else {
    return 0;
  }
}

/**
 * 
 * 大数乘法
 * @param {*} num 乘数1
 * @param {*} base 乘数2
 * @param {*} decimal 转换后的小数位数，默认为0 不保留小数
 * 
 */
export function bigMul(num, base, decimal = 0) {
  let temp = Number(num), baseTemp = Number(base);
  if (temp && baseTemp) {
    return bigDecimal.multiply(temp, baseTemp, decimal);
  } else {
    return 0;
  }
}