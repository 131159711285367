import React, { useEffect, useState } from "react";
import Apply from "@/components/Apply";
import Footer from "@/components/Footer";
import ApplyModal from "@/components/ApplyModal";
import { Popover } from "@hibo/HiboBiz";
import { mtClick } from "@/api/api";
const carouselContent = [
  {
    text: "线下生意越来越难做了，没想到上线外卖平台增加很多收入",
    name: "来自五金店老板",
  },
  {
    text: "我自己弄得一两个礼拜，你们竟然半天就帮我搞定了",
    name: "来自社区生鲜店老板",
  },
  {
    text: "你们真的很实惠，系统也很简单好操作",
    name: "来自水果店老板",
  },
  {
    text: "一台打印机可以打印多个平台的订单，太方便了",
    name: "来自眼镜店老板",
  },
];
const businessPainList = [
  {
    icon: "business_pain_1",
    bigText: "人效低",
    smallText: "多渠道接单",
  },
  {
    icon: "business_pain_2",
    bigText: "漏单",
    smallText: "接单不及时易漏单",
  },
  {
    icon: "business_pain_3",
    bigText: "成本高",
    smallText: "配送范围/时间受限",
  },
  {
    icon: "business_pain_4",
    bigText: "商品上架难",
    smallText: "商品多上架慢",
  },
];
const storeRxList = [
  {
    title: "商品复制",
    content: "模板店商品一键复制（商品、类目、价格...）",
    icon: "copyIcon",
    iconList: [
      {
        icon: "jdms",
        text: "京东秒送",
      },
      {
        icon: "mt",
        text: "美团",
      },
      {
        icon: "ele",
        text: "饿了么",
      },
      {
        icon: "dy",
        text: "抖音",
      },
    ],
  },
  {
    title: "聚合接单打印",
    content: "秒接单、0漏单、真人语音",
    icon: "printIcon",
    iconList: [
      {
        icon: "print",
      },
    ],
  },
  {
    title: "聚合发配送",
    content: "全自动发配送、比价发单、加小费，更省钱",
    icon: "deliveryIcon",
    iconList: [
      {
        icon: "ddks",
        text: "达达快送",
      },
      {
        icon: "mtpt",
        text: "美团跑腿",
      },
      {
        icon: "fnps",
        text: "蜂鸟配送",
      },
      {
        icon: "sftc",
        text: "顺丰同城",
      },
      {
        icon: "ss",
        text: "闪送",
      },
    ],
  },
];
const coreList = [
  {
    title: "聚合接单",
    titleIcon: "combineOrder_icon",
    channelIcon: ["jdms", "mt", "ele", "dy", "tbmc"],
    list: [
      "自动接单",
      "订单查询",
      "自动补单",
      "异常单处理",
      "手动拣货",
      "自动拣货",
    ],
  },
  {
    title: "聚合打印",
    titleIcon: "combinePrint_icon",
    list: [
      "WiFi版+USB",
      "4G版+USB",
      "WiFi版+4G版",
      "自动打印",
      "手动打印",
      "小票模板自定义",
      "真人语音",
    ],
  },
  {
    title: "聚合配送",
    titleIcon: "combineDelivery_icon",
    list: [
      "自动接单",
      "手动发单",
      "比价发单",
      "加小费",
      "配送回传",
      "转自送",
      "配送异常处理",
    ],
  },
  {
    title: "聚合售后",
    titleIcon: "combineSale_icon",
    list: [
      "缺货调整",
      "取消订单",
      "售后审核",
      "自动回评",
      "差评溯源",
      "客服消息",
    ],
  },
  {
    title: "门店管理",
    titleIcon: "storeManage_icon",
    list: [
      "营业时间设置",
      "营业状态修改",
      "门店置休消息通知",
      "角色管理",
      "子账号管理",
    ],
  },
];
const offline = ["线下单量：50单/日", "客单价：20元", "销售额：1000元/日"];
const online = [
  "仅用0.5天【京东秒送】【美团外卖】【饿了么】全部营业",
  "经营30天后，线上日订单：30单/日，客单价40元",
  "线上销售额：1200元/日",
];
const TakeoutPage = () => {
  const [showModal, setShowModal] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fade, setFade] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0); // 跳转后滚动到顶部

    const intervalId = setInterval(() => {
      setFade(false);
      setTimeout(() => {
        setCurrentIndex(
          (prevIndex) => (prevIndex + 1) % carouselContent.length
        );
        setFade(true);
      }, 500); // 动画持续时间
    }, 5000); // 切换间隔时间

    return () => clearInterval(intervalId);
  }, []);

  // 埋点
  const setDjMs = async (clickId, clickName) => {
    await mtClick("", "", clickId, clickName, "", "", {}, {});
  };

  const modalShow = () => {
    if (!showModal) {
      setShowModal(true);
    }
  };
  const onCancel = () => {
    setShowModal(false);
  };

  const handleMouseEnter = () => {
    setDjMs("func_BuyNow", "立即购买");
  };

  return (
    <div className="takeout_page_wrapper">
      {/* <Connect /> */}
      <ApplyModal isShow={showModal} onCancel={onCancel} />
      <div className="carousel_wrapper_front">
        <img
          style={{ width: "100%" }}
          src={`https://static.hiboos.com/static/yzt-home/homePage/take-out-v.webp`}
          // src={require('@/images/homePage/take_out.webp')}
          alt=""
        />
        <div className="opt_btn_wrapper">
          <p className="opt_btn btn_default" onClick={modalShow}>
            免费咨询
          </p>
          <Popover
            trigger="hover"
            placement="top"
            onMouseEnter={handleMouseEnter}
            content={
              <img
                src='https://static.hiboos.com/static/yzt-home/homePage/buy_qrcode.png'
                width={188}
                height={231}
                alt=""
              />
            }
          >
            <p className="opt_btn btn_primary" style={{ marginLeft: "10px" }}>
              立即购买
              <img
                src='https://static.hiboos.com/static/yzt-home/homePage/discount.png'
                width={60}
                height={18}
                alt=""
              />
            </p>
          </Popover>
        </div>

        <div
          className={`fade-${fade ? "in" : "out"} banner_carousel_container`}
        >
          <p className="text">{`“${carouselContent[currentIndex].text}”`}</p>
          <p className="name">{`—${carouselContent[currentIndex].name}`}</p>
        </div>
      </div>

      <div className="work_scene">
        <div className="business_pain_spot_wrapper">
          <p className="title_big">商家痛点</p>
          <div className="wrapper pain_content">
            {businessPainList.map((item, index) => {
              return (
                <div className="pain_item" key={index}>
                  <img
                    width={50}
                    height={50}
                    src={require(`@/images/takeout/${item.icon}.png`)}
                    alt=""
                  />
                  <p className="big_text">{item.bigText}</p>
                  <p className="small_text">{item.smallText}</p>
                </div>
              );
            })}
          </div>
        </div>
        {/* 解决方案 */}
        <div className="store_rx">
          <div style={{ margin: "0 50px" }}>
            <p className="title_big">零售小店系统解决方案</p>
            <div className="main_content wrapper">
              {storeRxList.map((item, index) => {
                return (
                  <div className="content_item" key={index}>
                    {index < 2 ? (
                      <div className="ellipse_icon">
                        <img
                          width={32}
                          height={50}
                          src={require("@/images/takeout/ellipse_icon.png")}
                          alt=""
                        />
                      </div>
                    ) : null}

                    <div className="title">
                      <span className="icon"></span>
                      <span className="text">{item.title}</span>
                    </div>
                    <div className="center_text">{item.content}</div>
                    <div className="icon_wrapper">
                      {item.iconList.map((items, indexs) => {
                        return (
                          <div className="icon_item" key={indexs}>
                            <img
                              width={`${item.icon == "printIcon" ? 108 : 50}`}
                              src={require(`@/images/takeout/${items.icon}.png`)}
                              alt=""
                            />
                            <p className="icon_text">{items?.text}</p>
                          </div>
                        );
                      })}
                    </div>
                    <img
                      width={70}
                      src={require(`@/images/takeout/${item.icon}.png`)}
                      alt=""
                      className="background_image"
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {/* business */}
        <div className="takeout_business">
          <div className="wrapper">
            <p className="title_big">更多能力</p>
            <div className="card_wrapper">
              <div className="card_item card_item_left">
                <div className="icon"></div>
                <p className="title">聚合聊天</p>
                <p className="text">多外卖平台客户聊天管理</p>
              </div>
              <div className="card_item card_item_center">
                <div className="icon"></div>
                <p className="title">评价管理</p>
                <p className="text">自动回复评价、一秒找差评订单</p>
              </div>
              <div className="card_item card_item_right">
                <div className="icon"></div>
                <p className="title">营业管理</p>
                <p className="text">多外卖平台门店营业时间管理</p>
              </div>
            </div>
          </div>
        </div>
        {/* 核心优势 */}
        <div className="core_advantage">
          <p className="title_big">产品功能/核心优势</p>
          <div className="content_wrapper wrapper">
            {coreList.map((item, index) => {
              return (
                <div className="item" key={index}>
                  <div className="title">
                    <img
                      width={24}
                      height={26}
                      src={require(`@/images/takeout/${item.titleIcon}.png`)}
                      alt=""
                    />
                    <span className="title_text">{item.title}</span>
                  </div>
                  <div className="content">
                    {item.titleIcon == "combineOrder_icon" ? (
                      <div className="channel_icon">
                        {item.channelIcon.map((icon, indexs) => {
                          return (
                            <img
                              width={24}
                              key={indexs}
                              src={require(`@/images/takeout/${icon}.png`)}
                              alt=""
                            />
                          );
                        })}
                      </div>
                    ) : null}
                    {item.list.map((text, ind) => {
                      return (
                        <p className="content_item" key={ind}>
                          {text}
                        </p>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {/* 客户案例 */}
        <div className="customer_case">
          <p className="title_big">客户案例</p>
          <p className="title_small">
            某五金百货超市：店内人员2人，经营品类为五金百货，1600+SKU
          </p>
          <p className="title_min_big">商家痛点</p>
          <div className="wrapper core_case">
            <div className="core_case_item">
              <img
                width={50}
                src={require(`@/images/takeout/core_case_1.png`)}
                alt=""
              />
              <span className="item_text">线上运营不了解、起店困难</span>
            </div>
            <div className="core_case_item">
              <img
                width={50}
                src={require(`@/images/takeout/core_case_2.png`)}
                alt=""
              />
              <span className="item_text">拓渠道周期长、人效低</span>
            </div>
          </div>
          <p className="title_min_big">合作效果</p>
          <div className="wrapper vs_wrapper">
            <div className="left">
              <div className="title">
                <img
                  width={215}
                  height={38}
                  src={require("@/images/takeout/offline_icon.png")}
                  alt=""
                />
              </div>
              {offline.map((item, index) => {
                return (
                  <div className="content_item" key={index}>
                    <p>{item}</p>
                  </div>
                );
              })}
              <div className="bottom">
                <div className="bottom_content">1000元/日</div>
                <p className="bottom_text">总营业额</p>
                <p className="bottom_text">2023-10</p>
              </div>
            </div>
            <div className="center">
              <img
                style={{ marginTop: "138px" }}
                width={56}
                height={28}
                src={require("@/images/takeout/VSIcon.png")}
                alt=""
              />
              <div className="bottom">
                <img
                  width={73}
                  height={76}
                  src={require("@/images/takeout/up_icon.png")}
                  alt=""
                  className="up_icon"
                />
                <p className="bottom_text">每天收入</p>
                <p className="bottom_text">增长120%</p>
              </div>
            </div>
            <div className="right">
              <div className="title">
                <img
                  width={215}
                  height={38}
                  src={require("@/images/takeout/online_icon.png")}
                  alt=""
                />
              </div>
              {online.map((item, index) => {
                return (
                  <div className="content_item" key={index}>
                    <p>{item}</p>
                  </div>
                );
              })}
              <div className="bottom">
                <div className="bottom_content">2200元/日</div>
                <p className="bottom_text">总营业额</p>
                <p className="bottom_text">2023-11</p>
              </div>
            </div>
          </div>
        </div>
        <Apply />
        <Footer />
      </div>
    </div>
  );
};
export default TakeoutPage;
