'use strict';
import CryptoJSCore from 'crypto-js/core';
import AES from 'crypto-js/aes';
import Pkcs7 from 'crypto-js/pad-pkcs7';
const S_KEY = "J@NcRfUjXn2r5u8x";
const S_PARAMETER = "t7w!z%C*F-JaNdRg";
export const utils = {
    fetchUrl: 'https://log-o2o.jd.com/v1/logging',
    searchParams(params) {
        return Object.keys(params)
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
            .join('&')
            .replace(/%20/g, '+');
    },
    // 验证项目基本信息
    verifyProParams(proBasicParams) {
        if (!('app_version' in proBasicParams)) {
            console.log('未定义版本号 - app_version！');
            return false;
        }
        if (!('app_name' in proBasicParams)) {
            console.log('未定义项目名称 - app_name！');
            return false;
        }
        if (!('plat_code' in proBasicParams)) {
            console.log('未定义平台类型 - plat_code');
            return false;
        }
        // if(!('channel_name' in proBasicParams)){
        //     console.log('未传入渠道名称 - channel_name');
        //     return false;
        // }
        return true;
    },
    // 验证设备的基本信息
    verifyDevParams(deviceBasicParams) {
        if (!('os' in deviceBasicParams)) {
            console.log('未定义操作系统 - os');
            return false;
        }
        if (!('device_id' in deviceBasicParams)) {
            console.log('未定义设备id - device_id');
            return false;
        }
        return true;
    },
    // 上报埋点
    requestApi(data, type, needEncrypt) {
        return new Promise((resolve, reject) => {
            if (data.length == 0) {
                reject();
            }
            const plainData = {
                logType: type || 'h5',
                json: JSON.stringify(data)
            };
            const encryptData = {
                logType: type || 'h5',
                json: encodeURIComponent(this.encrypt(JSON.stringify(data))),
                jef: 1
            };
            const body = !!needEncrypt ? encryptData : plainData;
            fetch(this.fetchUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: this.searchParams(body)
            })
                .then(response => {
                if (response === null || response === void 0 ? void 0 : response.ok) {
                    response.json().then(json => {
                        resolve(json);
                    });
                }
                else {
                    reject();
                }
            })
                .catch(() => {
                reject();
            });
        });
    },
    /**
 * api 加密
 */
    encrypt(data) {
        const CBCOptions = {
            iv: CryptoJSCore.enc.Utf8.parse(S_PARAMETER),
            mode: CryptoJSCore.mode.CBC,
            padding: Pkcs7
        };
        const key = CryptoJSCore.enc.Utf8.parse(S_KEY);
        const secretData = CryptoJSCore.enc.Utf8.parse(data);
        const encrypted = AES.encrypt(secretData, key, CBCOptions);
        return encrypted.toString();
    }
};
