
exports.STOCKGOODSQUERY = "stock-goods-query";
exports.STOCKSKUQUERY = "stock-sku-query";
exports.STOCKOPERATIONLOG = "stock-operation-log";
exports.AUTOPRINTORDER = "auto-print-order";  // 是否可以自动打印
exports.PCPRINTORDERIDS = "pc-print-orderIds";  // PC打印小票订单编号

exports.MARKETERPSYNC = "market-erp-sync";
exports.MARKETERPACTIVE = "market-erp-active";
exports.MARKETERPTAB = "market-erp-tab";


exports.BASICPOSFORM = "basic-pos-form";  // 基础信息 门店管理 储位信息页 查询条件
exports.BASICAREAFORM = "basic-area-form";  // 基础信息 门店管理 储区储位页 查询条件

// 价格管理
exports.PRICELIST = "price-list";  // 价格查询列表页
exports.PRICERULE = "price-rule";  // 价格规则列表页
exports.RESOURCEPKG = "resource-pkg-form";  // 资源包管理 列表页 查询条件

exports.COMBINECOUNTRECHECK = "combine-count-recheck";  // 订单履约-履约数据-门店复核开关
exports.MARKETERPAUTOFORM = "market-erp-auto-form";  // 促销活动 erp促销自动化
exports.COMBINECOUNTFORM = "combine-count-form";  // 订单履约-履约数据-查询条件
exports.FINANCEHIBOOS = "finance-hiboos-form";  // 财务对账 海博系统对账单 查询条件


exports.GOOD_ON_LINE_CHOICE_TYPE = "good-on-line-choice-type";
exports.GOOD_ON_LINE_ORG_CODE="good-on-line-org-code"
exports.GOOD_OFF_LINE_CREATE_SKU_ID="good-off-line-create-sku-id"
exports.GOOD_OFF_LINE_INFO="good-off-line-info"
exports.GOOD_CHOICE_STALIB_INFO="good-choice-stalib-info"
exports.GOOD_UPC ='good-upc'//进入标库是显示的upc码
exports.GOOD_ST_NAME ='good-st-name'//进入标库是显示的upc码
exports.GOOD_ON_LINE_SKU_ID ="good-on-line-sku-id"
exports.GOOD_ON_LINE_SPU_ID ="good-on-line-spu-id"
exports.GOOD_CREATE_FROM ="good-create-form" //进入创建页面中来源
exports.GOOD_FILL_IN = 'good-fill-in';
exports.GOOD_OPRATION_LOG_NAME="good-opration-log-name";
exports.GOOD_OPRATION_LOG_OUT_SKU_ID="good-opration-log-out-skuId";
exports.GOOD_OPRATION_LOG_="good-opration-log-id";
exports.GOOD_ON_LINE_QUERY = 'good_on_line_query'
exports.GOOD_ON_LINE_INFO ='good_on_line_info'
exports.GOOD_ON_LINE_QUERY_BY_LOCAL ='good_on_line_query_by_local'
exports.GOOD_TYPE= 'good-type'
exports.CREATE_GOOD_SUCCESS ="create-good-sucess";
exports.CREATE_GOOD_FROM_BACK_CATEGORY = "CREATE_GOOD_FROM_BACK_CATEGORY";
exports.GOOD_ON_LINE_INFO_FORM = "GOOD_ON_LINE_INFO_FORM";
exports.GOOD_ON_LINE_INFO_FORM_INFO = "GOOD_ON_LINE_INFO_FORM_INFO";
exports.GOOD_OPRATION_LOG_ID = "GOOD_OPRATION_LOG_ID";

exports.COMBINEADDGOODSFORM = "combine-addgoods-form";  // 订单履约-履约数据-补货数据-查询条件
exports.FINANCE_TOTAL_FORM = "FINANCE_TOTAL_FORM";  // 财务对账 汇总页 查询条件

// 商品日志优化
exports.GOOD_OPRATION_LOG_ONLINE_INFO = "GOOD_OPRATION_LOG_ONLINE_INFO";  // 线上商品日志回显信息
exports.GOOD_OPRATION_LOG_STORE_INFO = "GOOD_OPRATION_LOG_STORE_INFO";  // 门店商品日志回显信息

exports.GOOD_OPEN_OPRATION_LOG_ONLINE_INFO = "GOOD_OPEN_OPRATION_LOG_ONLINE_INFO";  // 线上商品日志回显信息 打开新标签页
exports.GOOD_OPEN_OPRATION_LOG_STORE_INFO = "GOOD_OPEN_OPRATION_LOG_STORE_INFO";  // 门店商品日志回显信息 打开新标签页

exports.PRICE_LOG_FORM = "PRICE_LOG_FORM";  // 价格日志 查询条件

exports.AUTHORITY_BUSINESS_WRITE_OFF_QUERY="AUTHORITY_BUSINESS_WRITE_OFF_QUERY";//核销配置查询
exports.GLOBAL_MENU_INFO = "GLOBAL_MENU_INFO";


//exports.GLOBAL_CHANNEL_ORG = "GLOBAL_CHANNEL_ORG";  // 根据商家编码获取全部渠道
exports.AUTHORITY_MANAGE_MERCHANT_CONFIG_LIST ="AUTHORITY_MANAGE_MERCHANT_CONFIG_LIST";//商家基础配置管理
exports.AUTHORITY_MANAGE_BUSINESS_LIST ="AUTHORITY_MANAGE_BUSINESS_LIST";//商家管理
exports.AUTHORITY_MANAGE_CONTRACT_LIST ="AUTHORITY_MANAGE_CONTRACT_LIST";//商家合同配置

exports.STORE_ACTIVITY_LIST = "STORE_ACTIVITY_LIST";  // 门店活动列表
exports.ORDER_DISPATCH_STATION_ID ="ORDER_DISPATCH_STATION_ID";//派单复核页面选择的门店id
exports.ORDER_DISPATCH_CURRENT_TAB ="ORDER_DISPATCH_CURRENT_TAB";//派单复核页面选择的当前tab页 goodsManage/toppingList
exports.ORDER_DISPATCH_PAGEINFO ="ORDER_DISPATCH_PAGEINFO";//派单复核页面pageinfo
exports.GOODS_MANAGE_TOPPING_LIST_QUERY ="GOODS_MANAGE_TOPPING_LIST_QUERY";//品牌神劵拓品

exports.AUTHORITY_MANAGE_ACCOUNT_LIST  ="AUTHORITY_MANAGE_ACCOUNT_LIST ";//运营后台商家结算单
exports.FINANCE_MANAGE_ACCOUNT_LIST  ="FINANCE_MANAGE_ACCOUNT_LIST ";//商家中台商家结算单

exports.FULLREDUCT_AUTO_ERP_FORM ="FULLREDUCT_AUTO_ERP_FORM";//满减自动化列表form

exports.STATION_LIST_QUERY = "STATION_LIST_QUERY";

exports.USER_LIST_QUERY = "USER_LIST_QUERY";  // 员工管理列表

exports.GOOD_OFF_LINE_QUERYINFO = "GOOD_OFF_LINE_QUERYINFO";  // 线下商品管理查询条件
exports.MARKET_SDM_QUERY = "MARKET_SDM_QUERY";  // 直降不对接erp列表 查询条件
exports.MARKET_SDMCOPY_FORM = "MARKET_SDMCOPY_FORM";   // 直降不对接erp 复制活动第一步表单数据
exports.MARKET_SDMCREATE_FORM = "MARKET_SDMCREATE_FORM";   // 直降不对接erp 创建活动第一步表单数据
exports.MARKET_ERP_SDMCOPY_FORM = "MARKET_ERP_SDMCOPY_FORM";   // 直降对接erp 复制活动第一步表单数据
exports.MARKET_ERP_SDMCREATE_FORM = "MARKET_ERP_SDMCREATE_FORM";   // 直降对接erp 创建活动第一步表单数据

exports.SUPPLIER_ACT_QUERY = "SUPPLIER_ACT_QUERY";   // 供应商分摊方案 查询条件

exports.STATION_LIST ="STATION_LIST";//所属门店列表

exports.RE_PAGE_INFO ="RE_PAGE_INFO";//埋点 信息 { ref_page_name:'',ref_page_url:'',current_page_name:'',current_page_url:''}



exports.SELECTGOODS_EXAM_FORM = "SELECTGOODS_EXAM_FORM";  // 智能选品 商品体检查询条件
exports.SELECTGOODS_LIST_FORM = "SELECTGOODS_LIST_FORM";  // 智能选品 选品清单查询条件
exports.SELECTGOODS_EFFECT_DETAIL = "SELECTGOODS_EFFECT_DETAIL";  // 智能选品 拓品效果分析-拓品商品明细查询条件

exports.XY_LIST_FORM = "XY_LIST_FORM";  // X元Y件 查询条件
exports.XY_CREATE_CHANNEL = "XY_CREATE_CHANNEL";  // X元Y件 创建、复制活动时选中的渠道

exports.ADJUSTPRICE_SDM_LIST_FORM = "ADJUSTPRICE_SDM_LIST_FORM";  // 直降促销智能定价 查询条件

exports.CATEGORYPRICE_SDM_LIST_FORM = "CATEGORYPRICE_SDM_LIST_FORM";  // 直降促销按品类调价 查询条件

exports.BG_LIST_FORM = "BG_LIST_FORM";  // 买赠 查询条件
exports.BG_CREATE_CHANNEL = "BG_CREATE_CHANNEL";  // 买赠 创建、复制活动时选中的渠道
// exports.BG_CREATE_TOKEN = "BG_CREATE_TOKEN";  // 买赠 创建、复制活动时token
exports.BG_CREATE_GIFTSNUM = "BG_CREATE_GIFTSNUM";  // 买赠 创建、复制活动时赠品数量

exports.AUTOSTORECHANGE_LIST_FORM = "AUTOSTORECHANGE_LIST_FORM";  // 自主切店任务列表页查询条件
exports.AUTOSTORECHANGE_FAILGOODS_FORM = "AUTOSTORECHANGE_FAILGOODS_FORM";  // 自主切店失败商品列表页查询条件

exports.STOREINSPECTION_FORM = "STOREINSPECTION_FORM";  // 巡店管理查询条件